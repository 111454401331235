.Herosection {
  background-color: #040320;
  width: 100%;
  padding-bottom: 20px;
  background-image: url("/public/Images/Ornament\ 57.svg");
  display: flex;
  align-items: center;
  justify-content: center;
}
.CONT {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CONT-h1 {
  font-family: "Inter";
  font-size: 80px;
  font-weight: 800;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  margin-top: 60px;
}
.CONT-p {
  font-weight: 400;
  font-size: 23px;
  line-height: 40px;
  text-align: center;
  color: #D1D1D1;
  width: 885px;
  height: 80px;
  margin-top: 10px;
}
.CONT-carousel {
  width: 100%;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  transition: transform 0.5s ease-in-out;
  overflow-x: hidden;
}
.carousel-item {
  transition: opacity 0.5s ease;
}
/* .carousel-image {
  width: 400px;
} */
.leftcont {
  width: 50%;
  padding-top: 140px;
}
.brr {
  display: none;
}
.boldtext {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 69px;
  color: #172b4d;
  margin-bottom: 15px;
}
.now {
  color: #5a27ae;
  border-bottom: 5px solid #5a27ae;
}
.mediumtext {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 40px;
  color: #5b5971;
  margin-bottom: 15px;
}
.rightcont {
  width: 45%;
  display: flex;
  justify-content: flex-end;
}
.rightcont img {
  width: 100%;
}
.googleLink {
  width: 88%;
  /* padding-top: 20px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.downldBtn{
  width: 50%;
}
.google {
  width: 70%;
}
.apple{
  width: 75%
}
.bn46 {
  width: 50%;
  /* height: 50px; */
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .CONT {
    padding: 30px 0px 0px 0px;
    flex-direction: column;
    text-align: center;
  }
  .leftcont {
    width: 100%;
    padding-top: 30px;
  }
  .brr {
    display: block;
  }
  .boldtext {
    font-weight: 800;
    /* font-size: 29px;
    line-height: 30px; */
    font-size: 34px;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .CONT-h1 {
    font-weight: 800;
    /* font-size: 29px;
    line-height: 30px; */
    font-size: 33px;
    width: 330px;
    line-height: 45px;
    text-align: center;
    margin-top: -1px;
  }
  .mediumtext {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .CONT-p {
    font-size: 15px;
    font-weight: 400;
    width: 320px;
    line-height: 25px;
    margin-top: 10px;
    text-align: center;
  }
  .break {
    display: none;
  }
  .rightcont {
    width: 100%;
    justify-content: flex-start;
  }
  .googleLink {
    width: 100%;
    padding-top: 10px;
    margin: auto;
  }
  .CONT-carousel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    overflow-x: auto;
    margin-top: -25px;
    margin-top: 70px;
    border-radius: 20px;
    overflow-x: hidden;
    gap: 10px;
  }

  .carousel-image {
    width: 260px;
    height: 245px;
  }

  .mobile-view {
    margin-top: -20px;
    width: 100%;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .CONT {
    padding: 0px 20px 0px 20px;
    flex-direction: column;
    text-align: center;
  }
  .leftcont {
    width: 100%;
    padding-top: 30px;
  }
  .brr {
    display: block;
  }
  .boldtext {
    font-weight: 700;
    /* font-size: 35px;
    line-height: 39px; */
    font-size: 40px;
    line-height: 44px;
    margin-bottom: 20px;
  }
  .mediumtext {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .break {
    display: none;
  }
  .rightcont {
    width: 100%;
    justify-content: center;
  }
  .rightcont img {
    width: 70%;
    margin: auto;
  }
  .googleLink {
    padding-top: 10px;
    margin: auto;
  }
}
@media only screen and (max-width: 978px) and (min-width: 900px) {
  .CONT {
    padding: 0px 0px 0px 40px;
  }
  .leftcont {
    width: 55%;
    padding-top: 100px;
  }
  .boldtext {
    font-size: 55px;
  }
  .break {
    display: none;
  }
}
.brr {
  display: block;
}
