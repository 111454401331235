.CreditSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5% 5%;
}
.landingPage {
  width: 100%;;
  padding-bottom: 60px;
  background-image: url("/public/Images/new-bg.jpg");
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 100px;
}
.landingPage h2 {
  font-weight: 800;
  font-size: 70px;
  line-height: 84.72px;
  text-align: center;
  width: fit-content;
  margin-top: 80px;
  color: #1C1939;
}
.landingPage-Flex {
  width: 90%;
  display: flex;
  align-items: last baseline;
  justify-content: center;
  gap: 30px;
  margin-top: 100px;
  margin-bottom: 50px;
}
.landing-Image {
  width: 380px;
}
.Creditleftcont {
  width: 47%;
}
.Creditleftcont img {
  width: 100%;
}
.Creditrightcont {
  width: 45%;
}
.smallHeadline {
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #27ae60;
  margin-bottom: 0;
}
.semiboldtext {
  font-weight: 800;
  font-size: 40px;
  line-height: 50px;
  color: #172b4d;
  margin-bottom: 15px;
}
.semimediumtext {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 15px;
}
.downloadNow {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 60px;
  scroll-behavior: smooth;
}
.downloadNow a {
  color: #3861fb;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .CreditSection {
    flex-direction: column;
    padding: 0px 20px 50px 20px;
  }
  .landingPage {
    padding: 0px 0px;
  }
  .Creditleftcont {
    width: 100%;
  }
  .headline2 {
    width: 100% !important;
  }
  .headline3 {
    width: 100% !important;
  }
  .Creditrightcont {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
  .landingPage h2 {
    font-weight: 800;
    /* font-size: 29px;
    line-height: 30px; */
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    margin-left: 5px;
    margin-top: 40px;
  }
  .one {
    flex-direction: column-reverse;
  }
  .p4 {
    width: 100% !important;
  }
  .three {
    flex-direction: column-reverse;
  }
  .smallHeadline {
    font-size: 17px;
    line-height: 40px;
  }
  .semiboldtext {
    font-weight: 800;
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .semimediumtext {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  .downloadNow {
    font-size: 16px;
  }
  .landingPage {
    width: 100%;
  }

  .landingPage h2 {
    width: 98%;
  }

  .landingPage-Flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0px 10px;
    margin-bottom: 30px;
  }

  .landingPage-Flex img {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  .CreditSection {
    flex-direction: column;
    padding: 0px 20px 50px 20px;
  }
  .Creditleftcont {
    width: 100%;
  }
  .Creditrightcont {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }

  .one {
    flex-direction: column-reverse;
  }
  .three {
    flex-direction: column-reverse;
  }
  .smallHeadline {
    font-size: 17px;
    line-height: 40px;
  }
  .semiboldtext {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 5px;
  }
  .semimediumtext {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 8px;
  }
}
