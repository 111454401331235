.faqsection {
  width: 90%;
  margin: auto;
  padding: 50px 20px 80px 20px;
  background-color: transparent !important;
}
.faqheadline {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 55px;
  text-align: center;
  color: #172b4d;
  padding-bottom: 30px;
}
.subhead {
  font-family: "Inter";
  font-style: normal;
}
.test {
  margin: auto;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 59px 48px; /* Corrected to use valid padding shorthand */
  gap: 34px;
  border-radius: 20px;
  background: #F4F6FC;
}
.accordions {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .faqsection {
    width: 100%;
    height: 150vh;
    margin: auto;
    padding: 20px 20px 0px 20px;
    background: "#F4F6FC";
    display: none;
  }
  .faqheadline {
    font-size: 36px;
    line-height: 38px;
    padding-bottom: 20px;
    text-align: left
  }
  .question {
    font-size: 15px;
    line-height: 20px;
    padding: 8px 0px;
  }
  .answer {
    font-size: 15px;
  }
  .test {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    height: fit-contents;
    padding: 130px 3px 0px 3px;
    background-color: transparent !important;
  }
  .accordions {
    width: 100%;
  }
  .accordions-mobile {
    margin-top: 150px;
  }
}
