.footer-container {
  display: flex;
  align-items: center;
  max-width: 90%;
  margin: auto;
  height: 15vh;
  justify-content: space-between;
}
.footer-copy{
  display: flex;
  justify-content: center;
  color: #172b4d;
  padding: 0 0 10px 0;
  background: inherit
}
.footer-navbar {
  height: 15vh;
  background: #040320;
}
.home {
  width: 20%;
}
.footer-logo {
  width: 60%;
  display: flex;
}
.footer-menu1 {
  display: flex;
  justify-content: space-between;
  width: 45%;
}
.footer-menu2 {
  display: flex;
  width: 20%;
  justify-content: flex-start;
}
.footer-menu1 li {
  font-family: "Inter";
  text-decoration: none;
  list-style: none;
  color: #D0D0D1;
  font-size: 15px;
  font-weight: 400;
}
.footer-menu1 a {
  text-decoration: none;
}
.footer-menu2 button {
  width: 40px;
  height: 40px;
  border: none;
  padding: 0px 0px;
  margin: 0px 7px;
  border-radius: 50%;
  background: #ffffff;
  font-size: 18px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}
.facebook {
  color: #1877f2;
}
.twitter {
  color: #1da1f2;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .footer-container {
    display: block;
    max-width: 100%;
    height: auto;
    padding-top: 30px;
  }
  .footer-navbar {
    height: auto;
  }
  .footer-menu1 {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 0px;
  }
  .footer-menu2 {
    width: 100%;
    padding-bottom: 20px;
  }
  .footer-menu2 button {
    width: 40px;
    height: 40px;
    padding: 0px 0px;
    margin: 10px 10px 0px 10px;
  }
  .footer-menu1 li {
    padding: 10px 10px;
    margin: 0px 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .footer-menu1 li a {
    font-size: 14px;
  }
  .footer-logo {
    width: 20%;
    display: flex;
    padding-left: 18px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .footer-container {
    display: block;
    max-width: 100%;
    height: auto;
    padding-top: 30px;
  }
  .footer-navbar {
    height: auto;
  }
  .footer-menu1 {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 0px;
  }
  .footer-menu2 {
    width: 100%;
    padding-bottom: 20px;
  }
  .footer-menu2 button {
    width: 40px;
    height: 40px;
    padding: 0px 0px;
    margin: 10px 10px 0px 10px;
  }
  .footer-menu1 li {
    padding: 10px 10px;
    margin: 0px 10px;
    font-size: 14px;
    font-weight: 400;
  }
  .footer-menu1 li a {
    font-size: 14px;
  }
  .footer-logo {
    width: 20%;
    display: flex;
    padding-left: 18px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 950px) and (min-width: 901px) {
  .footer-container {
    max-width: 100%;
  }
  .home {
    padding-left: 20px;
  }
}
