.downloadSection {
  width: 100%;
  display: flex;
  padding: 40px 0px;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;
}
.download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: auto;
  color: white;
  padding: 56px 40px 56px 40px;
  gap: 40px;
  border-radius: 50px;
  background: linear-gradient(102.18deg, #4306FF 2.27%, rgba(3, 41, 226, 0.925614) 37.95%, rgba(83, 136, 243, 0.975786) 81.98%, #7AB6FC 100.02%);
}
.downloadWriteUp {
  width: 100%;
  padding-top: 30px;
}
.download h3 {
  font-family: "Inter";
  margin-top: 30px;
  line-height: 39px;
  padding-bottom: 10px;
  font-size: 45px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.download p {
  font-family: "Inter";
  width: 499.62px;
  font-size: 20px;
  line-height: 28px;
  color: #e7e7e7;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .downloadSection {
    display: none;
  }
}
