@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "inter";
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.logo {
  width: 30%;
  display: flex;
}
.navbar {
  height: 15vh;
  line-height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 70px;
  background-color: #040320;
  border-bottom: 1px solid #ffffff2b;
}

.nav-links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75%;
}
.nav-links li {
  color: #FFFFFF;
  padding: 0px 10px;
  margin: 0px 10px;
  font-size: 18px;
  font-weight: 400;
}
button {
  border: none;
  background: none;
  font-size: 20px;
}
.mobile-menu-icon {
  display: none;
}
.googlePlay {
  width: 16%;
  padding: 10px 0px 0px 10px;
}
.navbarImg {
  width: 100%;
}

.download-app {
  width: 158px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 48px;
  padding: 16px, 24px, 16px, 24px;
  background-color: #3861FB;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .logo {
    display: flex;
    position: absolute;
    top: 17px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: #040320;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
    padding-bottom: 15px;
  }
  .nav-links-mobile li {
    font-size: 18px;
    padding-left: 20px;
    color: #FFFFFF;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    right: 30px;
    font-size: 30px;
    color: #fff;
  }
  .googlePlay {
    width: 38%;
    padding: 0px 0px 0px 20px;
  }
  .navbar {
    width: 100%;
    height: 10vh;
    line-height: 7vh;
  }
  .navbarImg {
    width: 100%;
  }
  .download-app {
    margin-left: 20px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    width: 168px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .logo {
    display: flex;
    position: absolute;
    width: 28%;
    top: 22px;
    left: 35px;
  }
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    width: fit-content;
    display: flex;
    align-items: center;
    border: 1px solid white;
    list-style: none;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background: white;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
  }
  .nav-links-mobile li {
    font-size: 18px;
    padding-left: 20px;
  }
  .mobile-menu-icon {
    display: block;
    position: absolute;
    right: 30px;
    font-size: 30px;
    color: #fff;
  }
  .googlePlay {
    width: 38%;
    padding: 0px 0px 0px 20px;
  }
  .navbar {
    height: 10vh;
    line-height: 7vh;
  }
  .navbarImg {
    width: 100%;
  }
}
@media only screen and (max-width: 978px) and (min-width: 900px) {
  .navbar {
    padding: 0px 30px;
  }
  .nav-links {
    width: 80%;
  }
}
