.HowItWorks {
  display: flex;
  justify-content: center;
  /* gap: 50px; */
  align-items: center;
  padding: 10% 20px 5% 60px;
  background-color: #040320;
}
.HowItWorks2 {
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
  padding: 10% 5%;
  background: linear-gradient(220.18deg, #4306FF 2.27%, #0329E2 37.95%, rgba(83, 136, 243, 0.975786) 81.98%, #7AB6FC 100.02%);
}
.HowItWorks3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10% 2% 10% 5%;
  gap: 20px;
  background-color: #ffffff;
  background-image: url("/public/Images/new-bg.jpg");
}
.HowItWorks4 {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 10% 5%;
  background-image: url('/public/Images/bg-mum-child.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
.Leftcont {
  width: 45%;
}
.headline3 {
  color: #1C1939;
  font-family: Inter;
  font-size: 48px;
  font-weight: 800;
  line-height: 60px;
  text-align: left;
  width: 563px;
  height: 120px;
  gap: 0px;
}
.p1 {
  /* width: 600px; */
}
.p3 {
  color: #000000 !important;
  font-family: Inter;
  font-size: 24px;
  font-weight: 400;
  line-height: 38.4px;
  text-align: left;
  width: 563px;
  height: 108px;
  gap: 0px;
}
.Leftcont p {
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #ECEBF9;
  margin-bottom: 40px;
}
.p2 {
  font-size: 24px;
  line-height: 40px;
  color: #ECEBF9;
  /* width: 550px !important; */
}
.p4 {
  font-size: 24px;
  line-height: 40px;
  color: #ECEBF9;
  /* width: 550px !important; */
}
.HowItWorksrightcont {
  width: 50%;
}
.HowItWorksrightcont2 {
  width: 50%;
}
.brrr {
  display: none;
}
.store {
  width: 100%;
  display: flex;
  gap: 20px;
}
.store20 {
  width: 100%;
  display: flex;
  gap: 20px;
}
.store0 {
  width: 100%;
  display: flex;
  gap: 20px;
}
.store1 {
  width: 100%;
  display: flex;
  gap: 20px;
}
.HowItWorksrightcont img {
  width: 100%;
}

/* .HowItWorksrightcont2 > img {
  display: none;
} */
.headline {
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
}
.headline2 {
  font-style: normal;
  font-weight: 800;
  width: 550px;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 30px;
}
.headline14 {
  font-style: normal;
  font-weight: 800;
  width: 550px;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  padding-bottom: 30px;
}
.step {
  position: relative;
  z-index: 999;
  display: flex;
}
.title {
  line-height: 1.5em;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #172b4d;
  margin-bottom: 15px;
}
.caption {
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
  color: #333333;
}
.step + .step {
  margin-top: 1.5em;
}
.step > div:not(:first-child) {
  margin-left: 1.5em;
  padding-left: 1em;
}
.circleCont {
  display: flex;
  justify-content: center;
}
.circle {
  background: #c8deff;
  position: relative;
  width: 2.4em;
  height: 2.4em;
  line-height: 1.1em;
  border-radius: 100%;
  color: #3861fb;
  text-align: center;
  box-shadow: 0 0 0 3px #fff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle:after {
  content: " ";
  position: absolute;
  display: block;
  right: 50%;
  left: 50%;
  height: 6vh;
  width: 2px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: #172b4d;
  z-index: -1;
}
.step:last-child .circle:after {
  display: none;
}
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .HowItWorks {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px;
  }
  .HowItWorks2 {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 5px 20px;
  }
  .HowItWorks3 {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
  .HowItWorks4 {
    width: 100%;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/public/Images/background-mobile.svg');
  }
  .Leftcont {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .HowItWorksrightcont {
    width: 100%;
  }
  .HowItWorksrightcont img {
    height: 400px;
  }
  .build-wealth {
    margin-top: -60px;
  }
  .brrr {
    display: block;
  }
  .headline {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 10px;
    text-align: center;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
  .headline2 {
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 20px;
    text-align: center;
    margin-top: 40px;
    width: 320px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .headline3 {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 100px;
    text-align: center;
    margin-top: 40px;
    width: 300px !important;
    margin: 40px auto 0px auto;
  }
  .headline14 {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 100px;
    text-align: left;
    margin-top: 5px;
    color: white;
    width: 300px;
  }
.Leftcont p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: -10px;
  width: 320px;
  margin-left: auto;
  margin-right: auto;
}
  .p2 {
    font-size: 14.5px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: -10px;
    width: 290px !important;
    margin: 0px auto;
  }
  .p3 {
    width: 100%;
    margin-top: -30px !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  .p4 {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important; 
    margin-top: -80px !important;  
  }
  .store {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
  }
  .store20 > img {
    width: 150px;
  }

  .store20 {
    width: 100%;
    gap: 8px;
    margin-top: -15px;
    margin-left: auto;
    margin-right: auto;
  }
  .store > img {
    width: 150px;
  }
  .store0 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: -40px;
  }
  .store0 > img {
    width: 150px;
  }
  .store1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: -80px;
    margin-bottom: -20px;
  }
  .store1 > img {
    width: 150px;
  }
  .title {
    line-height: 1.2em;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: #172b4d;
    margin-bottom: 13px;
  }
  .caption {
    font-size: 17px;
    line-height: 28px;
  }
  .circle:after {
    height: 6vh;
  }
}
@media only screen and (max-width: 900px) and (min-width: 481px) {
  .HowItWorks {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 20px;
  }
  .Leftcont {
    width: 100%;
  }
  .brrr {
    display: block;
  }
  .HowItWorksrightcont {
    width: 100%;
  }
  .headline {
    font-size: 30px;
    line-height: 35px;
    padding-bottom: 40px;
    text-align: center;
  }
  .circle:after {
    height: 5vh;
  }
}
