.Home {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.edit-image {
    width: 100%;
    height: 120vh;
    background-image: url('/public/Images/edit-image.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

.choose-plan {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    padding-bottom: 40px;
}

.choose-plan h2 {
    width: 389px;
    height: 60px;
    gap: 0px;
    font-family: Inter;
    font-size: 45px;
    font-weight: 800;
    line-height: 60px;
    text-align: center;
    color: #172B4D;
}

.plan-tab {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}

.ptb1 {
    width: Hug (150px)px;
    height: Hug (54px)px;
    padding: 18px 24px 18px 24px;
    gap: 8px;
    border-radius: 30px;
    background: #3861FB;
    color: #fff;
    cursor: pointer;
}

.ptb2 {
    width: Hug (150px)px;
    height: Hug (54px)px;
    padding: 18px 24px 18px 24px;
    gap: 8px;
    border-radius: 30px;
    border: 1px solid #D4D2E3;
    background: #fff;
    color: #000;
    cursor: pointer;
}

.plans {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding-bottom: 10px;
    position: relative;
}

.plans-card {
    width: 386px;
    height: 383px;
    padding: 48px 39px 30px 39px;
    gap: 52px;
    border-radius: 24px;
    border: 1px;
    border: 1px solid #C8C9CF;
    position: relative;
}

.plans-card-2 {
    width: 600px;
    height: 383px;
    padding: 48px 39px 30px 39px;
    gap: 52px;
    border-radius: 24px;
    border: 1px;
    border: 1px solid #C8C9CF;
    position: relative;
}

.plans-card p {
    font-family: Inter;
font-size: 28px;
font-weight: 600;
line-height: 38px;
text-align: left;
color: #1C1939;
}

.plans-card-2 p {
    font-family: Inter;
font-size: 28px;
font-weight: 600;
line-height: 38px;
text-align: left;
color: #1C1939;
}

.plans-card span {
    font-family: Inter;
font-size: 32px;
font-weight: 700;
line-height: 66px;
text-align: left;
color: #1C1939;
}

.plans-card-2 span {
    font-family: Inter;
font-size: 32px;
font-weight: 700;
line-height: 66px;
text-align: left;
color: #1C1939;
}

.list-style {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    list-style-type: circle;
    color: #1C1939;
}

.arrow-right {
    position: absolute;
    bottom: 30px;
    right: 30px;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
    .plans-card {
        width: 340px;
        height: 383px;
        padding: 48px 39px 30px 39px;
        gap: 52px;
        border-radius: 24px;
        border: 1px;
        border: 1px solid #C8C9CF;
        position: relative;
    }

    .plans-card-2 {
        width: 340px;
        height: 383px;
        padding: 48px 39px 30px 39px;
        gap: 52px;
        border-radius: 24px;
        border: 1px;
        border: 1px solid #C8C9CF;
        position: relative;
    }

    .choose-plan h2 {
        width: 100% !important;
        height: 60px;
        gap: 0px;
        font-family: Inter;
        font-size: 35px;
        font-weight: 800;
        line-height: 60px;
        text-align: center;
        color: #172B4D;
    }
}
